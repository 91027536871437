
import React from "react"



const Newsletter = () => {
  return(
    <div className="newsletter-wrapper">
      <iframe src="https://shawgravitt.substack.com/embed" height="320" frameBorder="0" scrolling="no" title="Sign up for my Substack newsletter" style={{width: 100+`%`}}></iframe>
    </div>
  )
}

export default Newsletter